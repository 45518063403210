<template>
  <router-view/>
</template>

<style>
#app {
  font-family: 'Montserrat', sans-serif; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin: 0;
}
img:focus {
  outline: none;
}

body{
  margin: 0;
  font-family: 'Montserrat', sans-serif; 
}

</style>